<template>
  <div id="dandelion-store" :class="route_name">
    <div v-show="idle_scan" class="scanner-idle" />
    <div v-show="!idle_scan" class="scanner-frame">
      <div id="interactive" class="scan-content viewport"></div>
    </div>

    <input ref="barcode-input" type="text" class="barcode-input" @input="onInputBarcode" />
    <router-view></router-view>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import Quagga from '@ericblade/quagga2';
window.Quagga = Quagga;

export default {
  name: 'dandelionStore',
  props: ['store_alias'],
  data() {
    return {
      quaggaState: {
        inputStream: {
          name: 'Live',
          type: 'LiveStream',
          constraints: {
            width: { min: 1280, ideal: 1920, max: 1920 },
            height: { min: 720, ideal: 1080, max: 1080 },
            facingMode: 'environment', // or user
            deviceId: undefined,
          },
          area: {
            // defines rectangle of the detection/localization area
            top: '0%', // top offset
            right: '0%', // right offset
            left: '0%', // left offset
            bottom: '0%', // bottom offset
          },
          singleChannel: false, // true: only the red color-channel is read
        },
        locator: {
          patchSize: 'small',
          halfSample: true,
        },
        numOfWorkers: 0,
        frequency: 6,
        decoder: {
          readers: ['upc_reader', 'ean_reader', 'ean_8_reader', 'code_128_reader'],
        },
        locate: true,

        lastResult: null,
        timeout: 60 * 5,
        processing_modal: false,
        debugResult: null,
        debugScore: null,
        debugCamera: null,
        deviceId: null,
      },
      idle_scan: true,
    };
  },
  computed: {
    idle_check() {
      return this.$store.state.dandelionStore.idle_check;
    },
    route_name() {
      return this.$route.name;
    },
  },
  watch: {
    idle_check: {
      handler() {
        if (this.idle_check === 'initiate') {
          this.idle_scan = false;
        }
        if (this.idle_check === 'idle') {
          this.idle_scan = true;
          window.Quagga.stop();
          this.$router.push(`/dandelion-store/${this.store_alias}`).catch((err) => {
            if (err.name !== 'NavigationDuplicated') {
              console.log(err);
            }
          });
        } else if (this.idle_check === 'active') {
          this.init();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  beforeDestroy() {
    clearTimeout(this.idleTimer);
    ['mousemove', 'mousedown', 'resize', 'keydown', 'touchstart', 'wheel'].forEach((event) => {
      window.removeEventListener(event, this.handleActivity);
    });
    window.Quagga.stop();
  },
  mounted() {
    ['mousemove', 'mousedown', 'resize', 'keydown', 'touchstart', 'wheel'].forEach((event) => {
      window.addEventListener(event, this.handleActivity);
    });
    this.resetTimer();
    this.init();
    window.Quagga.onProcessed((result) => {
      const drawingCtx = Quagga.canvas.ctx.overlay;
      const drawingCanvas = Quagga.canvas.dom.overlay;

      if (result) {
        if (result.boxes) {
          drawingCtx.clearRect(
            0,
            0,
            parseInt(drawingCanvas.getAttribute('width')),
            parseInt(drawingCanvas.getAttribute('height'))
          );
        }

        if (result.box) {
          Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: '#00F', lineWidth: 2 });
        }

        if (result.codeResult && result.codeResult.code) {
          Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'red', lineWidth: 3 });
        }
      }
    });

    window.Quagga.onDetected((result) => {
      let code = result.codeResult.code;
      let countDecodedCodes = 0;
      let err = 0;
      result.codeResult.decodedCodes.forEach((error) => {
        if (error.error != undefined) {
          countDecodedCodes++;
          err += parseFloat(error.error);
        }
      });
      this.debugResult = code;
      this.debugScore = err / countDecodedCodes;
      if (err / countDecodedCodes < 0.12) {
        // correct code detected
      } else {
        // probably wrong code
        code = '';
      }

      if (code) {
        this.goToProduct(code);
      }
    });

    this.$refs['barcode-input'].focus();
  },
  methods: {
    onInputBarcode: debounce(function (e) {
      this.goToProduct(e.target.value);
      this.$refs['barcode-input'].value = null;
      this.$refs['barcode-input'].focus();
    }, 100),
    handleActivity() {
      this.$refs['barcode-input'].focus();
      this.$store.dispatch('setIdleCheck', 'active');
      this.resetTimer();
    },
    resetTimer() {
      if (this.idleTimer) {
        clearTimeout(this.idleTimer);
      }
      this.idleTimer = setTimeout(() => {
        this.$store.dispatch('setIdleCheck', 'idle');
      }, 15000);
    },
    scan() {
      this.init();
    },
    async init() {
      await this.initCameraSelection();
      window.Quagga.init(this.quaggaState, async (err) => {
        if (err) {
          this.idle_scan = true;
          console.log(err);
          return;
        }
        window.Quagga.start();
        this.idle_scan = false;
      });
    },
    initCameraSelection() {
      return window.Quagga.CameraAccess.enumerateVideoDevices()
        .then((devices) => {
          let sourceZero;
          if (devices && devices.length > 3) {
            if (devices.findIndex((res) => res.label.indexOf('0') > -1 && res.label.indexOf('back') > -1) > -1) {
              sourceZero = devices.find(
                (res) => res.label.indexOf('0') > -1 && res.label.indexOf('back') > -1
              ).deviceId;
            } else {
              sourceZero = devices[devices.length - 1].deviceId;
            }
          }
          this.$set(this.quaggaState.inputStream.constraints, 'deviceId', sourceZero);
          if (devices && devices.length) {
            this.deviceId = 1;
          }
          return true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goToProduct(code) {
      this.$store.dispatch('setIdleCheck', 'active');
      this.resetTimer();
      this.$router.push(`/dandelion-store/${this.store_alias}/product/${code}`).catch((err) => {
        if (err.name !== 'NavigationDuplicated') {
          console.log(err);
        }
      });
    },
  },
};
</script>

<style>
#dandelion-store {
  max-height: 100vh;
  z-index: 3;
}
.scanner-idle,
.scanner-frame {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  z-index: 1;
  visibility: hidden;
}

.scanner-idle {
  background-color: #000;
}

.barcode-input {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
</style>
